<template>
    <!-- Site Header -->

    <div class="relative w-full h-auto overflow-auto bg-white home-a">
        <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0  css-144ctnr">
            <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
                <div>
                    <div class=""><span class="css-79e8z3">Why Qapin</span></div>
                    <h1
                        class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl xl:text-6xl css-1wfjlp">
                        Here’s what Qapin can do for you</h1>
                    <div class=" xl:mt-1">
                        <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                            style="font-size: 20px; line-height: 1.3; font-family: inherit;">
                            Qapin attracts some of the most in-demand Engineers, like the star performers below</p>
                    </div>
                    <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex"><button  @click="$router.push('/client/dashboard').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                            Post Job </button><br>
                        <button @click="$router.push('/freelancer').catch(()=>{})"
                            class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                            Find Job </button>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/c.svg" alt="" class="object-contain h-96 w-100"  />
                </div>
            </div>
        </div>

        <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div>
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi ">
                        Right coverage. Right skills. Right now.
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            Qapin’s powerful advanced search engine enables you to find a qualified inspector with the relevant experience and professional qualifications. In addition, you can find a professional who is local to the area where the inspection is to be performed.</p>
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                                As a result, your <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> doesn’t lose valuable time traveling and dealing with the associated expense and inconvenience.</p>
                                <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                                    The Qapin platform includes a management system with a free messaging platform. You can message the <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">freelancer</a> and share documents as often as you need, and you’ll be notified of their response in real time.</p>
                                    <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                        And, by adding a co-team, you can permit other people to manage your workload on your behalf.</p>
                        </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/why-qapin/Whyqapin-2.png" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
                </div>
            </div>
        </div>

        <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div class="mobile-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" padding-top: 50px;">
                        Optimize your processes for distributed businesses
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">Freelancers</a> using Qapin benefit from hassle-free payment for work they have completed. There’s no delay with payments and no possibility that your client will withhold funds when you’ve done the work, no matter how far away from them you are located..</p>

                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                                The way it works is that a client seeking an inspector pays Qapin for the work upfront, and we hold the money securely in Escrow. When you have completed the work and your client confirms they are satisfied, the money is immediately released to you.</p>
                                <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                                    It’s simple, safe, and secure. With no need for you to chase up clients, you can move on to your next job without the worry and the stress of unpaid invoices.</p>
                    </div>

                </div>
                <div class="mt-24 md:mt-0 mt-sm-2">
                    <img src="web/Web-images/why-qapin/Qapin-5.jpg" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
                </div>
                <div class="desktop-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" padding-top: 50px;">
                        Optimize your processes for distributed businesses
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            <a href="javascript:void(0)" @click="$router.push({name:'AgencySolutions',path:'/agency-solutions'}).catch(()=>{})">Freelancers</a> using Qapin benefit from hassle-free payment for work they have completed. There’s no delay with payments and no possibility that your client will withhold funds when you’ve done the work, no matter how far away from them you are located..</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            The way it works is that a client seeking an inspector pays Qapin for the work upfront, and we hold the money securely in Escrow. When you have completed the work and your client confirms they are satisfied, the money is immediately released to you.</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            It’s simple, safe, and secure. With no need for you to chase up clients, you can move on to your next job without the worry and the stress of unpaid invoices.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div>
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi ">
                        Field-verified metrics you can trust
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            Both clients and inspectors are encouraged to leave reviews and ratings for one another when the job is completed. These reviews are visible to help future clients and inspectors to identify people with which they think they can work well.</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                            Parties can choose one another based on past reviews and ratings to facilitate a smoother recruitment process.</p>
                    </div>
                </div>
                <div class="mt-24 md:mt-0">
                    <img src="web/Web-images/why-qapin/10.png" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
                </div>
            </div>
        </div>
        <div class="w-full pt-5 mb-20 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
            <div class="md:justify-between md:space-x-10 md:flex">
                <div class="mobile-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" padding-top: 50px;">
                        A Simple Process to Solve a Common Problem
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            Often, inspection schedules are fixed with suppliers, so clients <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">need inspectors</a> for specified dates. But when they are unable to locate a suitable inspector for those dates, things become difficult, and they have to settle for the first person who is available.</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            With Qapin, an easy-to-use filter system enables you to identify a candidate with the required skill set and who is available on the dates you specify and for the duration of the project.</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            With Qapin, an easy-to-use filter system enables you to identify a candidate with the required skill set and who is available on the dates you specify and for the duration of the project.</p>
                    </div>
                </div>
                <div class="mt-24 md:mt-0 mt-sm-2">
                    <img src="web/Web-images/why-qapin/Qapin-4.jpg" alt="" class="about-page-img" style="width: 100%; height: 500px;" />
                </div>
                <div class="desktop-sections">
                    <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi " style=" padding-top: 50px;">
                        A Simple Process to Solve a Common Problem
                    </h1>
                    <div class="mt-10 md:mt-6">
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            Often, inspection schedules are fixed with suppliers, so clients <a href="javascript:void(0)" @click="$router.push({name:'home',path:'/'}).catch(()=>{})">need inspectors</a> for specified dates. But when they are unable to locate a suitable inspector for those dates, things become difficult, and they have to settle for the first person who is available.</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            With Qapin, an easy-to-use filter system enables you to identify a candidate with the required skill set and who is available on the dates you specify and for the duration of the project.</p>
                        <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko mb-2">
                            With Qapin, an easy-to-use filter system enables you to identify a candidate with the required skill set and who is available on the dates you specify and for the duration of the project.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full h-auto px-4 pt-12 pb-10 xl:px-0 px-sm-1" style="background: rgba(242, 97, 60, 0.07)">
            <div class=" w-full mx-auto text-center xl:mx-auto" style="max-width: 80rem;">
                <!-- Quality Work -->
                <div class="mt-2">
                    <h2 class="text-xl fw-bold xl:text-4xl" style="font-weight: 800">
                        Grow your business with the best talent no matter where they are
                    </h2>
                </div>
                <!-- WEB version Categoties Agencies -->
                <div class="why-qapin">
                    <div class="grid grid-cols-1 gap-3 mt-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2">
                        <div
                            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                            <div class="w-8 p-4 bg-green-500 rounded-md block">
                            </div>
                            <h4 style="font-weight: 700"
                                class="mt-4 text-sm text-left text-theme-hover fw-bold xl:text-base">
                                Scalability
                            </h4>
                            <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                We ensure your global employee hiring goes as smoothly and stress-free as possible. We put you first and are always ready to go the extra mile. 
                            </p>
                        </div>

                        <div
                            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                            <div class="w-8 p-4 rounded-md block" style="background: #0082fa"></div>

                            <h4 style="font-weight: 700"
                                class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                Global coverage
                            </h4>
                            <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                Gain unrestricted access to talent in 150+ countries—and receive country-specific resources and support to meet compliance every time.
                            </p>
                        </div>

                        <div
                            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                            <div class="w-8 p-4 rounded-md block" style="background: #ff3c3c"></div>

                            <h4 style="font-weight: 700"
                                class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                Automation
                            </h4>
                            <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                Our platform, self-serve tools, country-specific resources, and support teams enable you to move quickly on hiring, paying, and providing benefits.
                            </p>

                        </div>

                        <div
                            class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                            <div class="w-8 p-4 rounded-md block" style="background: #cfdf42"></div>

                            <h4 style="font-weight: 700"
                                class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                Experience
                            </h4>
                            <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                Measure the consistency and control of your Global hiring to ensure ultimate performance and transparency at each stage of the process. Qapin offers in-app 24/7 support, advanced payments, and more.                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="xl:px-0">
            <div class="inset-0 xl:px-0">
                <div
                    class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto" style="background:  rgb(242 97 60);">
                    <div class="relative footer-vectors">
                   
                    </div>

                    <div class="py-6 text-center xl:py-14">
                        <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>

                        <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
                            Register for Qapin today
                        </h4>

                        <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto"
                            style="max-width: 60rem;">
                            it’s the future of inspection services. Bringing the inspection community
                            together in one easy to use platform, Qapin is simple, free and convenient
                            but don’t take our word for it - create your
                        </p>

                        <div class="px-10 mt-6 xl:px-0">
                            <button @click="$router.push('/login').catch(()=>{})"
                                class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                                Join for Free
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "tiny-slider/src/tiny-slider.scss";
export default {
    components: {
    },
    data() {
        return {
            isAuth: null,
        }
    },
    mounted() {
        this.isAuth = localStorage.getItem("token");
        if (this.$route.name == "ExpireLink") {
            this.isAuth = null;
        }
        this.getCurrentUserDetails();
    },
}
</script>
<style scoped>
.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}
.css-79e8z3 {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(13, 32, 73);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 170%;
    background-color: rgb(191 153 153 / 20%);
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.css-12k8mko {
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    max-width: 600px;
}

.css-1plhbgk {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(28, 28, 28);
    font-family: Silka, Helvetica, sans-serif !important;
    line-height: 170% !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 64px !important;
}

.css-ajm3pi {
    max-width: 40rem;
   padding-top: 100px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px ;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 28px !important;
}

.hero-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
}

.margin-15 {
    font-size: 19px;
}

.agenct-scale {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    margin-bottom: 16px;
    margin-top: 0;
    text-decoration: none;
}

@media (min-width: 1280px) {
    .xl\:text-6xl {
        font-size: 4.75rem;
        line-height: 1.2;
    }

    .xl\:pt-40 {
        padding-top: 12rem;
    }
    .xl\:max-w-7xl {
    max-width: 85rem;
}
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px ;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
</style>
