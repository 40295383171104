<template>
    <!-- Site Header -->

        <div class="relative w-full h-auto overflow-auto bg-white home-a">
            <div class="w-full pt-12 mb-20 xl:pt-40 md:pt-20 xl:px-0  css-144ctnr">
                <div class="md:justify-between md:flex w-full mx-auto xl:mx-auto xl:max-w-7xl">
                    <div>
                        <div class=""><span class="css-79e8z3">How Qapin Work</span></div>
                        <h1
                            class="w-full max-w-2xl text-4xl fw-600 xl:pt-6 md:pt-24 md:max-w-lg xl:max-w-2xl  css-1wfjlp">
                            Scale your business like a pro</h1>
                        <div class=" xl:mt-1">
                            <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                                style="font-size: 20px !important; line-height: 1.3 !important; font-family: inherit !important;">
                                Qapin is efficient solution for companies who want to hire expert source inspectors. With such
                                an enormous concentration of talent in one place, it’s never been easier to find the right
                                person to ensure products quality.</p>
                            <p class="w-full max-w-2xl text-4xl fw-400 md:max-w-lg xl:max-w-2xl xl:text-2xl css-1plhbgk"
                                style="font-size: 20px; line-height: 1.3; font-family: inherit;">
                                Grow your business, working when, how, and only with the companies you want.</p>
                        </div>
                        <div class="items-center mt-10 space-y-4 md:space-x-4 md:space-y-0 md:flex"><button  @click="$router.push('/client/dashboard').catch(()=>{})"
                                class="w-full px-4 py-2 text-base text-white transition-all duration-500 ease-in-out rounded-md shadow-sm cursor-pointer md:w-40 hover:bg-opacity-50 bg-primary-500 hover:shadow-lg">
                                Post Job </button><br>
                            <button @click="$router.push('/freelancer').catch(()=>{})"
                                class="w-full px-4 py-2 text-base text-black transition-all duration-500 ease-in-out border-solid rounded-md shadow-sm cursor-pointer md:w-40 border-1 hover:text-white border-primary-500 hover:bg-primary-500 hover:shadow-lg">
                                Find Job </button>
                        </div>
                    </div>
                    <div class="mt-24 md:mt-0 mt-sm-2">
                        <img src="web/scaleyoubusiness.svg" alt="" class="object-contain h-96 about-page-img w-100"  />
                    </div>
                </div>
            </div>

            <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">

                <div class="md:justify-between md:space-x-10 md:flex">
                    <div>
                        <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi"
                        style="padding-top: 120px; ">
                            <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                                GLOBAL COVERAGE
                            </p>
                            We’re everywhere you need
                        </h1>
                        <div class="mt-10 md:mt-6">
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Qapin gives you in-depth details on each talent, including qualifications, skills and employment history, most importantly immediate available talent so you get to select the talent you need eager to get work done.</p>
                        </div>
                    </div>
                    <div class="mt-24 md:mt-0 mt-sm-2">
                        <img src="web/Web-images/how-qapin-works/how-it-work-2.jpg" class="about-page-img" alt="" style="width: 100%; height: 500px;"  />
                    </div>
                </div>
            </div>

            <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
                <div class="md:justify-between md:space-x-10 md:flex">
                    <div class="mobile-sections">
                        <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi"
                        style="padding-top: 120px;">
                            <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                                TACKLE EVERY JOB
                            </p>
                            Manage ongoing projects
                        </h1>
                        <div class="mt-10 md:mt-6">
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Our project management system allows <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">freelancers</a> and clients to communicate directly and in real time for no cost. The system allows free messaging and share the documents whenever you want so you can stay on top of projects and communicate as often as you need.</p>

                        </div>

                    </div>
                    <div class="mt-24 md:mt-0 mt-sm-0">
                        <img src="web/Web-images/how-qapin-works/how-it-work-1.jpg" class="about-page-img" alt="" style="width: 100%; height: 500px;" />
                    </div>
                    <div class="desktop-sections">
                        <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi"
                        style="padding-top: 120px;">
                            <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                                TACKLE EVERY JOB
                            </p>
                            Manage ongoing projects
                        </h1>
                        <div class="mt-10 md:mt-6">
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Our project management system allows <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">freelancers</a> and clients to communicate directly and in real time for no cost. The system allows free messaging and share the documents whenever you want so you can stay on top of projects and communicate as often as you need.</p>

                        </div>

                    </div>

                </div>
            </div>

            <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1 mobile-padding" style="max-width: 80rem;">
                <div class="md:justify-between md:space-x-10 md:flex">

                    <div>
                        <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi"
                        style="padding-top: 120px;">
                            <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                                GROW FASTER
                            </p>
                            Scale your business the smart way
                        </h1>
                        <div class="mt-10 md:mt-6">
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Build your on-demand talent for scale. Rapidly flex service operations up or down by adding skill sets and locations, without investing in full-time staff.</p>

                        </div>

                    </div>
                    <div class="mt-24 md:mt-0 mt-sm-2">
                        <img src="web/Web-images/how-qapin-works/how-it-work-3.jpg" class="about-page-img" alt="" style="width: 100%; height: 500px;"  />
                    </div>
                </div>
            </div>


            <div class="w-full pt-5 mb-2 mx-auto xl:mx-auto px-sm-1" style="max-width: 80rem;">
                <div class="md:justify-between md:space-x-10 md:flex">
                    <div class="mobile-sections">
                        <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi"
                        style="padding-top: 120px;">
                            <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                            Make payment
                            </p>
                            Make payment
                        </h1>
                        <div class="mt-10 md:mt-6">
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Escrow is a simple way to organize <a href="javascript:void(0)" @click="$router.push({name:'SafePayments',path:'/safe-payments'}).catch(()=>{})">payment</a> and it’s completely secure for both parties</p>

                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                No more invoicing, <a href="javascript:void(0)" @click="$router.push({name:'SafePayments',path:'/safe-payments'}).catch(()=>{})">payment</a> follow up, or tax paperwork headaches. Receive one 1099(US Talents) tax document directly from Qapin each year</p>
                        </div>

                    </div>
                    <div class="mt-24 md:mt-0 mt-sm-0">
                        <img src="web/Web-images/how-qapin-works/how-it-work-3 (3).jpg" alt="" class="about-page-img" style="width: 100%; height: 500px;"  />
                    </div>
                    <div class="desktop-sections">
                        <h1 class="w-full max-w-sm text-4xl fw-bold xl:text-5xl css-ajm3pi"
                        style="padding-top: 120px;">
                            <p class="text-base uppercase w-400 text-primary-500 mb-sm-0" style="font-weight: 500;">
                            Make payment
                            </p>
                            Make payment
                        </h1>
                        <div class="mt-10 md:mt-6">
                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                Escrow is a simple way to organize <a href="javascript:void(0)" @click="$router.push({name:'SafePayments',path:'/safe-payments'}).catch(()=>{})">payment</a> and it’s completely secure for both parties</p>

                            <p class="max-w-xl text-base font-normal text-gray-400 css-12k8mko">
                                No more invoicing, <a href="javascript:void(0)" @click="$router.push({name:'SafePayments',path:'/safe-payments'}).catch(()=>{})">payment</a> follow up, or tax paperwork headaches. Receive one 1099(US Talents) tax document directly from Qapin each year</p>
                        </div>

                    </div>

                </div>
            </div>

            <div class="w-full h-auto px-4 pt-12 xl:px-0" style="background: rgba(242, 97, 60, 0.07)">
                <div class=" w-full mx-auto text-center xl:mx-auto" style="max-width: 80rem;">

                    <!-- Quality Work -->
                    <div class="mt-2">

                        <h2 class="text-xl fw-bold xl:text-4xl" style="font-weight: 800">
                            For Clients
                        </h2>

                    </div>

                    <!-- WEB version Categoties Agencies -->
                    <div class="for-clients">
                        <div class="grid grid-cols-1 gap-3 mt-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                            <div
                                class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                                <div class="w-8 p-4 bg-green-500 rounded-md block">

                                </div>

                                <h4 style="font-weight: 700"
                                    class="mt-4 text-sm text-left text-theme-hover fw-bold xl:text-base">
                                    Post job
                                </h4>
                                <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                    Simply log onto Qapin, post the job with required skills, and receive responses immediately.
                                </p>
                            </div>

                            <div
                                class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                                <div class="w-8 p-4 rounded-md block" style="background: #0082fa"></div>

                                <h4 style="font-weight: 700"
                                    class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                    Choose a Talent
                                </h4>
                                <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                    Quickly filter talent according to required job skills, location, ratings and more. Vet
                                    favorites and choose the best.
                                </p>
                            </div>

                            <div
                                class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                                <div class="w-8 p-4 rounded-md block" style="background: #ff3c3c"></div>

                                <h4 style="font-weight: 700"
                                    class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                    Review & Pay
                                </h4>
                                <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                    Review deliverables to ensure the work met your expectations before releasing funds from
                                    escrow.
                                </p>

                            </div>


                        </div>


                    </div>

                    

                </div>
            </div>
            
            <div class="w-full h-auto px-4 pt-12 pb-10 xl:px-0" style="background: rgba(242, 97, 60, 0.07)">
                <div class=" w-full mx-auto text-center xl:mx-auto" style="max-width: 80rem;">

                    <!-- Quality Work -->
                    <div class="mt-2">

                        <h2 class="text-xl fw-bold xl:text-4xl" style="font-weight: 800">
                            For <a href="javascript:void(0)" @click="$router.push({name:'BasicInfo',path:'/sign-up'}).catch(()=>{})">Freelancers</a>
                        </h2>

                    </div>

                    <!-- WEB version Categoties Agencies -->
                    <div class="for-freelancers">
                        <div class="grid grid-cols-1 gap-3 mt-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                            <div
                                class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                                <div class="w-8 p-4 bg-green-500 rounded-md block">

                                </div>

                                <h4 style="font-weight: 700"
                                    class="mt-4 text-sm text-left text-theme-hover fw-bold xl:text-base">
                                    Apply Jobs
                                </h4>
                                <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                    Simply log onto Qapin and apply projects suitable to your experience, skills, and
                                    background.
                                </p>
                            </div>

                            <div
                                class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                                <div class="w-8 p-4 rounded-md block" style="background: #0082fa"></div>

                                <h4 style="font-weight: 700"
                                    class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                    Get discovered by top companies
                                </h4>
                                <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                    Qapin is the first-place companies search for inspection service providers. If you’re a
                                    Inspection Engineer or Agency, you need to be on Qapin.
                                </p>
                            </div>

                            <div
                                class="z-10 px-3 pt-3 transition-all duration-700 ease-in-out bg-white border border-white rounded-md cursor-pointer hover:bg-primary-500 hover:border-primary-500 hover:shadow-xl">
                                <div class="w-8 p-4 rounded-md block" style="background: #ff3c3c"></div>

                                <h4 style="font-weight: 700"
                                    class="mt-4 text-sm  text-theme-hover text-left fw-bold xl:text-base">
                                    Work when & where you want
                                </h4>
                                <p class="mt-2 text-left xl:text-lg fw-400 sm:text-sm">
                                    Make sure your profile is full by work experience in your region that fit your interests and
                                    expertise.
                                </p>

                            </div>


                        </div>


                    </div>

                    

                </div>
            </div>

            <div class="xl:px-0">
                <div class="inset-0 xl:px-0">
                    <div class="left-0 right-0 w-full mx-auto bg-center shadow-xl xl:px-10 xl:h-auto"
                        style="background:  rgb(242 97 60);">
                        <div class="relative footer-vectors">

                        </div>

                        <div class="py-6 text-center xl:py-14">
                            <p class="text-sm uppercase fw-600 text-white text-primary-500">JOIN QAPIN </p>

                            <h4 class="mt-2 text-xl text-white fw-bold md:text-5xl">
                                Register for Qapin today
                            </h4>

                            <p class="max-w-sm mx-auto mt-4 text-sm text-white xl:text-lg fw-200 xl:w-auto"
                                style="max-width: 60rem;">
                                it’s the future of inspection services. Bringing the inspection community
                                together in one easy to use platform, Qapin is simple, free and convenient
                                but don’t take our word for it - create your
                            </p>

                            <div class="px-10 mt-6 xl:px-0">
                                <button @click="$router.push('/login').catch(()=>{})"
                                    class="w-40 px-4 py-2 text-base text-black transition-all duration-500 ease-in-out rounded-md bg-white shadow-sm cursor-pointer md:w-52 hover:bg-opacity-50 hover:text-black fw-200 xl:w-auto bg-primary-500 hover:shadow-lg">
                                    Join for Free
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TheHomeSiteFooter class="" v-if="!isAuth" />
        </div>
        
</template>

<script>
// import TheHomeSiteHeader from "../../TheHomeSiteHeader.vue";
// import TheHomeSiteHeader from "../../components/TheHomeSiteHeader.vue";
// import TheHomeSiteFooter from "./TheHomeSiteFooter.vue";
import "tiny-slider/src/tiny-slider.scss";
export default {
    components: {
        // TheHomeSiteFooter,
        //  TheHomeSiteHeader
    },
    data() {
        return {
            isAuth: null,
        }
    },
    mounted() {
        this.isAuth = localStorage.getItem("token");
        if (this.$route.name == "ExpireLink") {
            this.isAuth = null;
        }
        this.getCurrentUserDetails();
    },
}
</script>
<style scoped>
.css-144ctnr {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-flow: row nowrap;
    column-gap: 64px;
    padding: 126px 64px;
    min-height: 567px;
    background: rgba(242, 97, 60, 0.07);
}

.css-79e8z3 {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(13, 32, 73);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 170%;
    background-color: rgb(191 153 153 / 20%);
    padding: 4px 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.css-12k8mko {
    margin: 0px;
    color: rgb(6, 18, 44);
    font-family: Silka, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    max-width: 600px;
}

.css-1plhbgk {
    margin: 0px;
    letter-spacing: 0.00938em;
    color: rgb(28, 28, 28);
    font-family: Silka, Helvetica, sans-serif !important;
    line-height: 170% !important;
    font-weight: 400 !important;
    font-size: 18px !important;
}

.css-1wfjlp {
    margin-top: 0px;
    letter-spacing: 0.00938em;
    color: rgb(16, 16, 16);
    font-family: Silka, Helvetica, sans-serif;
    line-height: 120%;
    font-weight: 700 !important;
    font-size: 64px !important;
}

.css-ajm3pi {
    max-width: 40rem;
   padding-top: 100px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 28px !important;
}
.hero-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    line-height: 75px;
}

.margin-15 {
    font-size: 19px;
}

.agenct-scale {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.36;
    margin-bottom: 16px;
    margin-top: 0;
    text-decoration: none;
}

@media (min-width: 1280px) {
    .xl\:text-6xl {
        font-size: 4.75rem;
        line-height: 1.2;
    }

    .xl\:pt-40 {
        padding-top: 12rem;
    }
    .xl\:max-w-7xl {
    max-width: 85rem;
}
}
@media (max-width: 480px) {

.mt-24{
  margin-top: 0px;
}
.pb-24 {
  padding-bottom: 0px;
}



.css-ajm3pi {
  max-width: 40rem;
  padding-top: 0px;
  margin: 0px 0px 24px;
  letter-spacing: 0.00735em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 43px;
  font-weight: 700 !important;
  max-width: 600px !important;
  font-size: 25px !important;
}

.css-1wfjlp {
  margin-top: 0px;
  letter-spacing: 0.00938em;
  color: rgb(16, 16, 16);
  font-family: Silka, Helvetica, sans-serif;
  line-height: 120%;
  font-weight: 700 !important;
  font-size: 28px !important;
}
}
@media screen and (min-width: 320px) and (max-width: 815px) {
    .mb-20{
        margin-bottom:0px!important;
    }
}
</style>
